.job-homescreen-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.job-homescreen-form {
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 50vw;
  justify-content: center;
}

.job-homescreen-form-container {
  display: inline-flex;
  flex-direction: column;
}

.job-homescreen-title {
  display: inline-block;
  font-size: 5vw;
  margin: 0;
  white-space: nowrap;
  padding: 1em;
  text-align: center;
}

.job-homescreen-input-container {
  display: flex;
  flex-direction: row;
}

.job-homescreen-input-container > input { 
  font-size: 3vw;
  width: 50vw;
}

.job-homescreen-input-container > button {
  font-size: 3vw;
}