.job-totals-info-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1em;
}

.job-totals-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1em;
}

.job-totals-info .left {
  display: inline-block;
  margin-left: 0.75em;
  margin-right: 9em;
}

.job-totals-info .right {
  display: inline-block;
}

.job-totals-info .left p {
  margin: 0;
}

.job-totals-info .right p {
  margin: 0;
}

@media only screen and (min-width : 1200) {

}

@media screen and (min-width: 1024px) and (max-width: 1600px)  {

}

@media screen and (max-width: 1023px)  {
  .job-totals-info-main {
    flex-direction: column;
  }

  .job-sub-info > div {
    flex-direction: column  !important;
  }

  .job-print {
    padding: 1rem;
  }

  .job-google-map-btn {
    margin-top: unset !important;
  }

  .job-badge-container {
    flex-direction: column;
  }

  .select-text-nostyle {
    display: none;
  }

  .job-badge {
    margin: auto;
  }

  .job-print-body {
    overflow-x: scroll;
    border: 1px solid grey;
  }
}
