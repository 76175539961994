body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  font-family: 'PT Mono', monospace;
}

.App {
  width: 100vw;
  height: auto;
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }

    .job-print {
      padding: 0 !important;
      margin: 0 !important;
    }
}

.sticky-stuff {
	display:block;
	position: -webkit-sticky;
	position: sticky;
	bottom:0;
	background-color: white;
	z-index: 1;
  flex-direction: column !important;
  padding-bottom: 1em;
  padding-left : 1em;
}

.job-actions {
  height: 20vh;
}

.job-actions-container {
  display: flex;
  flex-direction: column;
}

.job-actions-tile .job-actions-success {
  display: none;
}

.job-actions-success {
  font-weight: 600;
  position: absolute !important;
  top: 0;
  left: 0;
  border: unset !important;
  background-color: rgba(76, 209, 55, 1);
  color: #FFFFFF;
  height: 4.5em !important;
}

.job-actions-tile {
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid black;
  padding: 0.65em;
  width: 4.5em;
/*  max-height: 4.5em;
  height: auto;*/
  text-align: center;
  transition: all 0.3s;
  justify-content: center;
  position: relative;
  font-size: 1em !important;
  margin-right: 1em;
}

.job-actions-tile p,b {
  margin-top: 0.1em;
  margin-bottom: 0;
}

.job-actions-tile img {
  display: inline-block;
  align-self: center;
  width: auto;
  height: 2.25em;
}

.job-actions-tile:hover {
/*  width: 6em; */
  color: #FFFFFF;
  background-color: #353b48;
  font-weight: 600;
}

.job-actions-tile:hover {
/*  width: 6em; */
  color: #FFFFFF;
  background-color: #353b48;
  font-weight: 600;
}

.job-actions-tile:active {
/*  width: 6em; */
  color: #FFFFFF;
  background-color: #353b48;
  font-weight: 600;
}


.job-actions-tile:active .job-actions-success {
  background-color: #44bd32;
}

.job-actions-tile:hover .job-actions-success {
  background-color: #44bd32;
}

.job-actions-tile:focus .job-actions-success {
  background-color: #44bd32;
}

.small-tile {
  width: 6.5em;
  height: 2em;
  margin-right: 0;
  padding: 0;
  font-size: 1em;
  color: #FFFFFF;
  border: unset;
  font-size: 1.25em;
  flex-direction: row;
  justify-content: unset;
}

.small-tile .main-action-title {
  display: block;
  opacity: 1;
  color: #000000;
  margin-top: 0.4em;
  margin-left: 0.25em;
}

.small-tile > p {
  display: none;
  opacity: 0;
}

.small-tile:hover > p {
  display: block;
  margin-top: 0.5em;
  opacity: 1;
}

.small-tile:hover .main-action-title {
  color: #FFFFFF;
  text-transform: lowercase;
}

.small-tile:hover {
  width: 10em;
  background-color: #353b48;
}


.small-tile:focus > p {
  display: block;
  margin-top: 0.5em;
  opacity: 1;
}

.small-tile:focus .main-action-title {
  color: #FFFFFF;
  text-transform: lowercase;
}

.small-tile:focus {
  width: 10em;
  background-color: #353b48;
}


.job-print {
    padding: 12px;
}

.job-print .category-dropdown {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Add some styling */
    float: left;
    min-width: 50px;
    display: block;
    font-size: inherit;
    padding-left: 5px;
    width: 100%;
    max-width: 320px;
    float: right;
    line-height: 1.75;
    color: #333;
    background-color: #ffffff;
    background-image: none;
    -ms-word-break: normal;
    border-color: gray;
    border-width: 0px;
    border-right-width: 1px;
    border-radius: 0px;
    word-break: normal;
}

.job-print .close-button {
    border-width: 0px;
    background-color: rgba(0, 0, 0, 0);
    color: inherit;
}

.job-print-body input {
    font-size: 12px;
    padding: 0px;
}

.job-print-footer {
  display: block;
}

.div-shrink-animation {
  transition: all 0.5s;
}

@-webkit-keyframes closeOut {
    0% {
        height: 50vh;
    }
    50% {
        height: 25vh;
    }
    100% {
        height: 0vh;
    }
}

@keyframes closeOut {
    0% {
        height: 50vh;
    }
    50% {
        height: 25vh;
    }
    100% {
        height: 0vh;
    }
}

.job-print-footer img {
  padding: 0.5em;
  display: inline-block;
}

.job-print-footer img:hover {
  cursor: pointer;
  /*height: 10em;*/
  box-shadow: 0px 0px 8px 1px hsla(0, 0%, 0%, .3), inset 0px 0px 2px 1px #4cd137;
}

.job-print-footer img:active {
  cursor: pointer;
  /*height: 10em;*/
  box-shadow: 0px 0px 8px 1px hsla(0, 0%, 0%, .3), inset 0px 0px 2px 1px #4cd137;
}

.job-print-row-split {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
}

.job-print-row-split .left-most {
    margin-right: auto;
    margin-left: 0;
}

.job-print-row-split .right-most {
    margin-left: auto;
    margin-right: 0;
}

.job-print-sigs-box {
    padding: 5px;
}

.job-print-sig {
    margin: 5px;
}

body {
    padding: 0;
}

/* Alert */

#alert {
    position: relative;
}

#alert:hover:after {
    background: hsla(0, 0%, 0%, .8);
    border-radius: 3px;
    color: #f6f6f6;
    content: 'Click to dismiss';
    font: bold 12px/30px sans-serif;
    height: 30px;
    left: 50%;
    margin-left: -60px;
    position: absolute;
    text-align: center;
    top: 50px;
    width: 120px;
}

#alert:hover:before {
    border-bottom: 10px solid hsla(0, 0%, 0%, .8);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: '';
    height: 0;
    left: 50%;
    margin-left: -10px;
    position: absolute;
    top: 40px;
    width: 0;
}

#alert:target {
    display: none;
}

.alert {
    background-color: #c4453c;
    background-image: linear-gradient(135deg, transparent, transparent 25%, hsla(0, 0%, 0%, .1) 25%, hsla(0, 0%, 0%, .1) 50%, transparent 50%, transparent 75%, hsla(0, 0%, 0%, .1) 75%, hsla(0, 0%, 0%, .1));
    background-size: 20px 20px;
    box-shadow: 0 5px 0 hsla(0, 0%, 0%, .1);
    color: #f6f6f6;
    display: block;
    font: bold 16px/40px sans-serif;
    height: 40px;
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: -45px;
    width: 100%;
    -webkit-animation: alert 1s ease forwards;
    animation: alert 1s ease forwards;
}
/* Animation */

@-webkit-keyframes alert {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        top: 0;
    }
}

@keyframes alert {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        top: 0;
    }
}

.cancelBtn {
  width: 4em;
  height: 4em;
  background: url("/img/cancel.png") no-repeat;
  background-size: 2.5em;
}

.select-text {
  transition: background 0.5s linear;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.select-text:hover {
  background: #353b48aa;
  color: white;
  transition: background 0s linear;
}

.select-text:active, .select-text:focus {
  background: #4cd137;
}


.select-text-nostyle {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}


.job-flexbox-row {
  display: flex;
  flex-direction: row;
}

.job-text-item {
  margin-right: 0.5em !important;
}

.navigation-bar {
  display: block;
  outline: 2px solid black;
}

.navigation-bar a {
  margin-right: 0.5em;
  background: #6c7ae0;
  color: #fff;
  text-decoration: none;
}

.navigation-bar a:hover {
  color: #ddd;
}

.navigation-bar a:first-child {
  margin-left: 0.5em;
}

.navigation-bar a:last-child {
  float: right;
}

.job-badge {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  min-width: 2.75em;
  max-width: 4em;
  text-align: center;
  transition: all 0.5s;
}

.job-badge-vwork:hover {
  background: #FFFFFF;
  color: #0586BD;
  cursor: pointer;
  transform: translateY(0.25em);
}

.job-badge-ipad {
  background: #2ecc71;
  color: #FFFFFF;
}

.job-badge-vwork {
  background: #0586BD;
  color: #FFFFFF;
  box-shadow: 1px 1px 1px 1px #0586BD, -1px 0px 2px 1px rgba(0,0,0,0.5);
}
.job-print-header {
    font-size: 15px;
}

.job-badge-container {
  display: flex;
  flex-direction: row;
}

.job-number {
  display: inline-block;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 0.5em;
  text-align: center;
  font-weight: 600;
}

.job-number-unpadded {
  display: inline-block;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 0em;
  padding-right: 0.25em;
  text-align: center;
  font-weight: 600;
}

.job-detail {
  display: flex;
  flex-direction: column;
  font-size: 1.25em;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.job-detail button{
  margin-right: 0.5em;
}

.job-detail-text {
  display: inline-block;
}

.job-detail-text p {
  margin: 0;
}

.job-sub-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1em;
  margin-top: 1vh;
  color: #666;
}

.job-sub-info .left {
  display: inline-block;
}

.job-sub-info .right {
  display: inline-block;
}

.job-sub-info .left p {
  margin: 0;
}

.job-sub-info .right p {
  margin: 0;
}


/*
  TODO: Themes

  Default: #6c7ae0
  BRH Green: #80a940
  BRH Blue: #5481ab
*/
.accordion__title {
  text-align: center;
  cursor: pointer;
  background-color: #6c7ae0; /* BRH Blue Color */
  transition: height 0.3s;
  color: #FFFFFF;
}

.accordion__title:hover {
  height: 8em;
}

.accordion__hidden_text {
  opacity: 0;
  height: 0;
  color: #FFFFFF;
}

.accordion__title:hover .accordion__hidden_text {
  opacity: 1;
}


.accordion__item h3 {
  margin-top: 0em;
}

.accordion__body {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: #eee;
}

.job-coupon {
  width: 40vw;
  margin-bottom: 1em;
}

.job-explanation {
  height: 5vh;
}

.no-margin {
  margin: 0 !important;
}

.no-touch-image {
  cursor: pointer;
  -webkit-user-select:none;
  -webkit-touch-callout: none;
  pointer-events: auto;
}

.thumbnail {
  padding: 0em !important;
  cursor: pointer;
  border: unset;
  -webkit-user-select:none;
  -webkit-touch-callout: none;
  pointer-events: auto;
}

.thumbnail:hover {
  box-shadow: unset !important;
}

.thumbnail:active {
  box-shadow: unset !important;
}

.thumbnail:focus {
  box-shadow: unset !important;
}

.job-tables {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.job-table-container {
  display: inline-block;
  margin-top: 1em;
  width: 100%;
}

@media print {
  .job-table-container {
    display: block !important;
  }

  .job-print-body {
    display: flex;
    flex-direction: column;
  }

  .tbl-print-fix {
    display: inline-block !important;
  }

  .table-fill {
    width: 100vw !important;
  }
}
@media screen and (min-width: 1824px) {
  .job-table-container {
    margin-right: 1em;
  }
}

/*** Table Styles **/

.table-fill {
  display: table;
  background: white;
  border-radius:10px;
  border-collapse: collapse;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  font-size: 1rem;
}

/*
  TODO: Themes

  Default: #6c7ae0
  BRH Green: #80a940
  BRH Blue: #5481ab
*/
th {
  color:#D5DDE5;
  background:#6c7ae0;
  font-size: calc(0.5vw + 0.75rem);
  border-bottom:4px solid #9ea7af;
  border-right: 1px solid #343a45;
  font-weight: 100;
  padding: 0.5vw;
  text-align:left;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  vertical-align:middle;
}

th:first-child {
  border-top-left-radius:3px;
}

th:last-child {
  border-top-right-radius:3px;
  border-right:none;
}

tr {
  border-top: 1px solid #C1C3D1;
  border-bottom: 1px solid #C1C3D1;
  color:#666B85;
  font-weight:normal;
  text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
}

tr:hover td {
  background:#ececff;
  color:#666666;
  /*border-top: 1px solid #22262e;*/
}

tr:first-child {
  border-top:none;
}

tr:last-child {
  border-bottom:none;
}

tr:nth-child(odd) td {
  background:#EBEBEB;
}

tr:nth-child(odd):hover td {
  background:#ececff;
}

tr:last-child td:first-child {
  border-bottom-left-radius:3px;
}

tr:last-child td:last-child {
  border-bottom-right-radius:3px;
}

td {
  background:#FFFFFF;
  /*padding:0.5em;*/
  text-align:left;
  vertical-align:middle;
  font-weight:300;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #C1C3D1;
}

td:last-child {
  border-right: 0px;
}

th.text-left {
  text-align: left;
}

th.text-center {
  text-align: center;
}

th.text-right {
  text-align: right;
}

td.text-left {
  text-align: left;
}

td.text-center {
  text-align: center;
}

td.text-right {
  text-align: right;
}

.react-autosuggest__container {
  display: block;
  flex-grow: 1;
  text-align: right;
}



.Resizer {
    background: #000000b0;
    opacity: .2;
    z-index: 1;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    transition: all 500ms ease-in-out;
    background: #505050;
}

 .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
    margin-right: 0.5em;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.job-google-map-container {
  height: 89vh;
  width: 100%;
}

.job-google-map-marker {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid #f44336;
  background-color: white;
  text-align: center;
  color: #3f51b5;
  font-size: 16;
  font-weight: bold;
  padding: 4;
  cursor: pointer;
}

.job-google-map-marker > p {
  font-size: 1em;
}

.job-google-map-btn {
  border: none;
  background: none;
  opacity: 0.65;
  cursor: pointer;
  transition: all 0.25s;
  font-size: 1.2rem;
  margin-top: -1.8rem;
}

.job-google-map-btn:hover {
  opacity: 1;
  font-size: 2em;
}

.job-google-map-modal-btn {
  display: inline-block;
  position: relative;
  top: 3em;
  right: 1em;
  float: right;
  border: none;
  background: url('/img/exit.png') no-repeat;
  background-size: 2em 2em;
  height: 2em;
  width: 2em;
  opacity: 0.65;
  cursor: pointer;
  transition: all 0.25s;
  z-index: 1;
}

.job-google-map-modal-btn:hover {
  opacity: 1;
  fontSize: 1.1em;
}

.actionableButton {
  border: none !important;
  box-shadow: none !important;
  opacity: 0.65;
  cursor: pointer;
  padding: 0px !important;
}

.actionableButton:hover, .actionableButton:focus {
  opacity: 1;
}

.deleteItemBtn {
  background: url("/img/trashcan.png") no-repeat;
  background-size: contain;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1rem;
  left: calc(5rem - 6px);
}

.addBtn {
  background: url("/img/inbox-down.png") no-repeat;
  background-size: contain;
}

.saveBtn {
  background: url("/img/accept.png") no-repeat;
  background-size: contain;
}

.cancelBtn {
  background: url("/img/cancel.png") no-repeat;
  background-size: contain;
}

.deleteBtn {
  background: url("/img/delete.png") no-repeat;
  background-size: contain;
}

.editBtn {
  background: url("/img/pencil.png") no-repeat;
  background-size: contain;
}

.editAddBtn {
  background: url("/img/pencil-add.png") no-repeat;
  background-size: contain;
}

.input-action-buttons {
  display: inline-flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: space-evenly;
  text-align: right;
}

.input-action-buttons button {
  display: inline-block !important;
  width: 2.75em;
  height: 2.75em;
  opacity: 0.65;
  box-shadow: 0px 0px 1px 0px #000000ab;
}

.input-action-buttons button:hover, .input-action-buttons button:focus {
  width: 3em !important;
  height: 3em !important;
  box-shadow: 0px 0px 2px 1px #4cd137;
  opacity: 1;
}

.tbl-cell-print-fix {
  margin: 0.15em !important;
}

@media print {
  .tbl-cell-print-fix {
    margin-top: 0.15em !important;
  }
}

.Dropdown-root {
    position: relative
}

.Dropdown-control {
    position: relative;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: 0;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease
}

.Dropdown-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, .06)
}

.Dropdown-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    margin-top: -ceil(2.5);
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0
}

.is-open .Dropdown-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px
}

.Dropdown-menu {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch
}

.Dropdown-menu .Dropdown-group>.Dropdown-title {
    padding: 8px 10px;
    color: #333;
    font-weight: 700;
    text-transform: capitalize
}

.Dropdown-option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, .8);
    cursor: pointer;
    display: block;
    padding: 8px 10px
}

.Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px
}

.Dropdown-option:hover {
    background-color: #f2f9fc;
    color: #333
}

.Dropdown-option.is-selected {
    background-color: #f2f9fc;
    color: #333
}

.Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px
}

.react-numeric-input > input {
  padding-right: 0px !important;
  padding-left: 0px !important;
  width: 100%;
  min-width: 8em;
}


.job-fix-select {
  padding-right: 0;
  margin-right: 0;
}

.job-fix-select > div > div {
  width: 12em !important;
}
.no-select {
  -ms-user-select: none;
      user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.overflow-text {
  text-overflow: ellipsis; /* will make [...] at the end */
  width: 4em; /* change to your preferences */
  white-space: nowrap; /* paragraph to one line */
  overflow:hidden; /* older browsers */
}

/* Fields CSS */
.image-field {
  display: inline-block;
  position: relative;
}

.image-field img {
  width: 64px;
  height: 64px;
  cursor: zoom-in !important;
  padding: 0em !important;
  border: unset;
}

.image-field img:hover {
  border: unset;
  width: 64px;
  height: 64px;
}

.photo-preview {
  height: 225px;
  margin: auto 0rem;
}

.full-page-image-preview {
	width: 100%;
}

.full-page-image-preview:hover {
	cursor: unset;
	width: 100% !important;
	height: auto !important;
}

.dock-controls {
	display: flex;
	flex-direction: 'row';
	justify-content: 'space-evenly';
}

.dock-controls > div {
	display: inline-block;
}

.react-contextmenu {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: .25rem;
    color: #373a3c;
    font-size: 16px;
    margin: 2px 0 0;
    min-width: 160px;
    outline: none;
    opacity: 0;
    padding: 5px 0;
    pointer-events: none;
    text-align: left;
    transition: opacity 250ms ease !important;
}

.react-contextmenu.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
}

.react-contextmenu-item {
    background: 0 0;
    border: 0;
    color: #373a3c;
	cursor: pointer;
    font-weight: 400;
    line-height: 1.5;
    padding: 3px 20px;
    text-align: inherit;
    white-space: nowrap;
}

.react-contextmenu-item.react-contextmenu-item--active,
.react-contextmenu-item.react-contextmenu-item--selected {
    color: #fff;
    background-color: #20a0ff;
    border-color: #20a0ff;
    text-decoration: none;
}

.react-contextmenu-item.react-contextmenu-item--disabled,
.react-contextmenu-item.react-contextmenu-item--disabled:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
    color: #878a8c;
}

.react-contextmenu-item--divider {
    border-bottom: 1px solid rgba(0,0,0,.15);
    cursor: inherit;
    margin-bottom: 3px;
    padding: 2px 0;
}
.react-contextmenu-item--divider:hover {
    background-color: transparent;
    border-color: rgba(0,0,0,.15);
}

.react-contextmenu-item.react-contextmenu-submenu {
	padding: 0;
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item {
}

.react-contextmenu-item.react-contextmenu-submenu > .react-contextmenu-item:after {
    content: "\25B6";
    display: inline-block;
    position: absolute;
    right: 7px;
}

.example-multiple-targets::after {
    content: attr(data-count);
    display: block;
}


.job-totals-info-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1em;
}

.job-totals-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1em;
}

.job-totals-info .left {
  display: inline-block;
  margin-left: 0.75em;
  margin-right: 9em;
}

.job-totals-info .right {
  display: inline-block;
}

.job-totals-info .left p {
  margin: 0;
}

.job-totals-info .right p {
  margin: 0;
}

@media only screen and (min-width : 1200) {

}

@media screen and (min-width: 1024px) and (max-width: 1600px)  {

}

@media screen and (max-width: 1023px)  {
  .job-totals-info-main {
    flex-direction: column;
  }

  .job-sub-info > div {
    flex-direction: column  !important;
  }

  .job-print {
    padding: 1rem;
  }

  .job-google-map-btn {
    margin-top: unset !important;
  }

  .job-badge-container {
    flex-direction: column;
  }

  .select-text-nostyle {
    display: none;
  }

  .job-badge {
    margin: auto;
  }

  .job-print-body {
    overflow-x: scroll;
    border: 1px solid grey;
  }
}

.loadingScreen {
   transition: all 750ms ease-out;
   width: 100vw;
   height: 100vh;
}

.loadingScreen-enter {
	opacity: 1;
  height: 100vh;
}

.loadingScreen-enter-active {
   opacity: 1;
   height: 100vh;
}

.loadingScreen-exit {
	opacity: 0.01;
  height: 1vh;
}

.loadingScreen-exit-active {
   opacity: 0.01;
   height: 1vh;
 }

.confirmScreen {
   transition: all 600ms ease-in;
   width: 100vw;
   height: 100vh;
}

.confirmScreen-enter {
	opacity: 1;
  height: 100vh;
}

.confirmScreen-enter-active {
   opacity: 1;
   height: 100vh;
}

.confirmScreen-exit {
	opacity: 0.01;
  height: 1vh;
}

.confirmScreen-exit-active {
   opacity: 0.01;
   height: 1vh;
 }

.chargeScreen {
   transition: all 600ms ease-in;
   width: 100vw;
   height: 100vh;
}

.chargeScreen-enter {
	opacity: 1;
  height: 100vh;
}

.chargeScreen-enter-active {
   opacity: 1;
   height: 100vh;
}

.chargeScreen-exit {
	opacity: 0.01;
  height: 1vh;
}

.chargeScreen-exit-active {
   opacity: 0.01;
   height: 1vh;
 }

/* Route container */
.login-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0;
}

/* Form styling */
.login-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80vh;
  width: 30vw;
}

.login-form .login-title {
  font-size: 30px;
  color: #403866;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;

  width: 100%;
  display: block;
}

/* Input fields */
.login-form input {
  outline: none;
  border: none;
  overflow: visible;
  margin: 0;
  touch-action: manipulation;
}

.login-form .input-field {
  display: flex;
  flex-direction: row;
  background: #e6e6e6;
  margin-bottom: 16px;
}

.input-field .email-field {
  color: #403866;
  line-height: 1.2;
  font-size: 18px;

  display: block;
  width: 100%;
  background: transparent;
  height: 62px;
  padding: 0 20px 0 38px;
}

.input-field .password-field {
  color: #403866;
  line-height: 1.2;
  font-size: 18px;

  display: block;
  width: 100%;
  background: transparent;
  height: 62px;
  padding: 0 20px 0 38px;
}

/* Error Messages */
.error-message {
  position: relative;
  top: 0.75em;
  right: -1em;
  width: 0px;
  height: 0px;
}

.error-message p {
  white-space: nowrap;
  color: #e84118;
}

/* Show password */
.show-password-field {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 1em;
}

.show-password-field input[type='checkbox'] {
  width: 1em;
  height: 1em;
  font-size: 2em;
  margin-right: 0.5em;  
}

.show-password-field p {
  margin-top: 0.5em;
  margin-bottom: 0;
  font-size: 1em;
}

.submit-button {
  font-size: 1em;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
  height: 62px;
  background-color: #827ffe;
  border-radius: 3px;
  transition: all 0.4s;
  border: none;
}

.submit-button:hover {
  background-color: #403866;
}
.job-homescreen-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.job-homescreen-form {
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 50vw;
  justify-content: center;
}

.job-homescreen-form-container {
  display: inline-flex;
  flex-direction: column;
}

.job-homescreen-title {
  display: inline-block;
  font-size: 5vw;
  margin: 0;
  white-space: nowrap;
  padding: 1em;
  text-align: center;
}

.job-homescreen-input-container {
  display: flex;
  flex-direction: row;
}

.job-homescreen-input-container > input { 
  font-size: 3vw;
  width: 50vw;
}

.job-homescreen-input-container > button {
  font-size: 3vw;
}

