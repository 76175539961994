.job-google-map-container {
  height: 89vh;
  width: 100%;
}

.job-google-map-marker {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 3px solid #f44336;
  background-color: white;
  text-align: center;
  color: #3f51b5;
  font-size: 16;
  font-weight: bold;
  padding: 4;
  cursor: pointer;
}

.job-google-map-marker > p {
  font-size: 1em;
}

.job-google-map-btn {
  border: none;
  background: none;
  opacity: 0.65;
  cursor: pointer;
  transition: all 0.25s;
  font-size: 1.2rem;
  margin-top: -1.8rem;
}

.job-google-map-btn:hover {
  opacity: 1;
  font-size: 2em;
}

.job-google-map-modal-btn {
  display: inline-block;
  position: relative;
  top: 3em;
  right: 1em;
  float: right;
  border: none;
  background: url('/img/exit.png') no-repeat;
  background-size: 2em 2em;
  height: 2em;
  width: 2em;
  opacity: 0.65;
  cursor: pointer;
  transition: all 0.25s;
  z-index: 1;
}

.job-google-map-modal-btn:hover {
  opacity: 1;
  fontSize: 1.1em;
}
