.confirmScreen {
   transition: all 600ms ease-in;
   width: 100vw;
   height: 100vh;
}

.confirmScreen-enter {
	opacity: 1;
  height: 100vh;
}

.confirmScreen-enter-active {
   opacity: 1;
   height: 100vh;
}

.confirmScreen-exit {
	opacity: 0.01;
  height: 1vh;
}

.confirmScreen-exit-active {
   opacity: 0.01;
   height: 1vh;
 }
