.job-badge {
  display: inline-block;
  border: 1px solid rgba(0, 0, 0, 0.5);
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 0.5em;
  padding-right: 0.5em;
  min-width: 2.75em;
  max-width: 4em;
  text-align: center;
  transition: all 0.5s;
}

.job-badge-vwork:hover {
  background: #FFFFFF;
  color: #0586BD;
  cursor: pointer;
  transform: translateY(0.25em);
}

.job-badge-ipad {
  background: #2ecc71;
  color: #FFFFFF;
}

.job-badge-vwork {
  background: #0586BD;
  color: #FFFFFF;
  box-shadow: 1px 1px 1px 1px #0586BD, -1px 0px 2px 1px rgba(0,0,0,0.5);
}