.no-select {
  user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
}

.overflow-text {
  text-overflow: ellipsis; /* will make [...] at the end */
  width: 4em; /* change to your preferences */
  white-space: nowrap; /* paragraph to one line */
  overflow:hidden; /* older browsers */
}

/* Fields CSS */
.image-field {
  display: inline-block;
  position: relative;
}

.image-field img {
  width: 64px;
  height: 64px;
  cursor: zoom-in !important;
  padding: 0em !important;
  border: unset;
}

.image-field img:hover {
  border: unset;
  width: 64px;
  height: 64px;
}

.photo-preview {
  height: 225px;
  margin: auto 0rem;
}
