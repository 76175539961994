.chargeScreen {
   transition: all 600ms ease-in;
   width: 100vw;
   height: 100vh;
}

.chargeScreen-enter {
	opacity: 1;
  height: 100vh;
}

.chargeScreen-enter-active {
   opacity: 1;
   height: 100vh;
}

.chargeScreen-exit {
	opacity: 0.01;
  height: 1vh;
}

.chargeScreen-exit-active {
   opacity: 0.01;
   height: 1vh;
 }
