.job-print-header {
    font-size: 15px;
}

.job-badge-container {
  display: flex;
  flex-direction: row;
}

.job-number {
  display: inline-block;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 0.5em;
  text-align: center;
  font-weight: 600;
}

.job-number-unpadded {
  display: inline-block;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 0em;
  padding-right: 0.25em;
  text-align: center;
  font-weight: 600;
}

.job-detail {
  display: flex;
  flex-direction: column;
  font-size: 1.25em;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.job-detail button{
  margin-right: 0.5em;
}

.job-detail-text {
  display: inline-block;
}

.job-detail-text p {
  margin: 0;
}

.job-sub-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1em;
  margin-top: 1vh;
  color: #666;
}

.job-sub-info .left {
  display: inline-block;
}

.job-sub-info .right {
  display: inline-block;
}

.job-sub-info .left p {
  margin: 0;
}

.job-sub-info .right p {
  margin: 0;
}


/*
  TODO: Themes

  Default: #6c7ae0
  BRH Green: #80a940
  BRH Blue: #5481ab
*/
.accordion__title {
  text-align: center;
  cursor: pointer;
  background-color: #6c7ae0; /* BRH Blue Color */
  transition: height 0.3s;
  color: #FFFFFF;
}

.accordion__title:hover {
  height: 8em;
}

.accordion__hidden_text {
  opacity: 0;
  height: 0;
  color: #FFFFFF;
}

.accordion__title:hover .accordion__hidden_text {
  opacity: 1;
}


.accordion__item h3 {
  margin-top: 0em;
}

.accordion__body {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  background-color: #eee;
}

.job-coupon {
  width: 40vw;
  margin-bottom: 1em;
}

.job-explanation {
  height: 5vh;
}

.no-margin {
  margin: 0 !important;
}

.no-touch-image {
  cursor: pointer;
  -webkit-user-select:none;
  -webkit-touch-callout: none;
  pointer-events: auto;
}

.thumbnail {
  padding: 0em !important;
  cursor: pointer;
  border: unset;
  -webkit-user-select:none;
  -webkit-touch-callout: none;
  pointer-events: auto;
}

.thumbnail:hover {
  box-shadow: unset !important;
}

.thumbnail:active {
  box-shadow: unset !important;
}

.thumbnail:focus {
  box-shadow: unset !important;
}

.job-tables {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.job-table-container {
  display: inline-block;
  margin-top: 1em;
  width: 100%;
}

@media print {
  .job-table-container {
    display: block !important;
  }

  .job-print-body {
    display: flex;
    flex-direction: column;
  }

  .tbl-print-fix {
    display: inline-block !important;
  }

  .table-fill {
    width: 100vw !important;
  }
}
@media screen and (min-width: 1824px) {
  .job-table-container {
    margin-right: 1em;
  }
}

/*** Table Styles **/

.table-fill {
  display: table;
  background: white;
  border-radius:10px;
  border-collapse: collapse;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  font-size: 1rem;
}

/*
  TODO: Themes

  Default: #6c7ae0
  BRH Green: #80a940
  BRH Blue: #5481ab
*/
th {
  color:#D5DDE5;
  background:#6c7ae0;
  font-size: calc(0.5vw + 0.75rem);
  border-bottom:4px solid #9ea7af;
  border-right: 1px solid #343a45;
  font-weight: 100;
  padding: 0.5vw;
  text-align:left;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  vertical-align:middle;
}

th:first-child {
  border-top-left-radius:3px;
}

th:last-child {
  border-top-right-radius:3px;
  border-right:none;
}

tr {
  border-top: 1px solid #C1C3D1;
  border-bottom: 1px solid #C1C3D1;
  color:#666B85;
  font-weight:normal;
  text-shadow: 0 1px 1px rgba(256, 256, 256, 0.1);
}

tr:hover td {
  background:#ececff;
  color:#666666;
  /*border-top: 1px solid #22262e;*/
}

tr:first-child {
  border-top:none;
}

tr:last-child {
  border-bottom:none;
}

tr:nth-child(odd) td {
  background:#EBEBEB;
}

tr:nth-child(odd):hover td {
  background:#ececff;
}

tr:last-child td:first-child {
  border-bottom-left-radius:3px;
}

tr:last-child td:last-child {
  border-bottom-right-radius:3px;
}

td {
  background:#FFFFFF;
  /*padding:0.5em;*/
  text-align:left;
  vertical-align:middle;
  font-weight:300;
  text-shadow: -1px -1px 1px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #C1C3D1;
}

td:last-child {
  border-right: 0px;
}

th.text-left {
  text-align: left;
}

th.text-center {
  text-align: center;
}

th.text-right {
  text-align: right;
}

td.text-left {
  text-align: left;
}

td.text-center {
  text-align: center;
}

td.text-right {
  text-align: right;
}

.react-autosuggest__container {
  display: block;
  flex-grow: 1;
  text-align: right;
}



.Resizer {
    background: #000000b0;
    opacity: .2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

.Resizer:hover {
    -webkit-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;
    background: #505050;
}

 .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
    margin-right: 0.5em;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
