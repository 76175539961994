* {
  font-family: 'PT Mono', monospace;
}

.App {
  width: 100vw;
  height: auto;
}

@media print
{
    .no-print, .no-print *
    {
        display: none !important;
    }

    .job-print {
      padding: 0 !important;
      margin: 0 !important;
    }
}

.sticky-stuff {
	display:block;
	position: -webkit-sticky;
	position: sticky;
	bottom:0;
	background-color: white;
	z-index: 1;
  flex-direction: column !important;
  padding-bottom: 1em;
  padding-left : 1em;
}

.job-actions {
  height: 20vh;
}

.job-actions-container {
  display: flex;
  flex-direction: column;
}

.job-actions-tile .job-actions-success {
  display: none;
}

.job-actions-success {
  font-weight: 600;
  position: absolute !important;
  top: 0;
  left: 0;
  border: unset !important;
  background-color: rgba(76, 209, 55, 1);
  color: #FFFFFF;
  height: 4.5em !important;
}

.job-actions-tile {
  display: inline-flex;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid black;
  padding: 0.65em;
  width: 4.5em;
/*  max-height: 4.5em;
  height: auto;*/
  text-align: center;
  transition: all 0.3s;
  justify-content: center;
  position: relative;
  font-size: 1em !important;
  margin-right: 1em;
}

.job-actions-tile p,b {
  margin-top: 0.1em;
  margin-bottom: 0;
}

.job-actions-tile img {
  display: inline-block;
  align-self: center;
  width: auto;
  height: 2.25em;
}

.job-actions-tile:hover {
/*  width: 6em; */
  color: #FFFFFF;
  background-color: #353b48;
  font-weight: 600;
}

.job-actions-tile:hover {
/*  width: 6em; */
  color: #FFFFFF;
  background-color: #353b48;
  font-weight: 600;
}

.job-actions-tile:active {
/*  width: 6em; */
  color: #FFFFFF;
  background-color: #353b48;
  font-weight: 600;
}


.job-actions-tile:active .job-actions-success {
  background-color: #44bd32;
}

.job-actions-tile:hover .job-actions-success {
  background-color: #44bd32;
}

.job-actions-tile:focus .job-actions-success {
  background-color: #44bd32;
}

.small-tile {
  width: 6.5em;
  height: 2em;
  margin-right: 0;
  padding: 0;
  font-size: 1em;
  color: #FFFFFF;
  border: unset;
  font-size: 1.25em;
  flex-direction: row;
  justify-content: unset;
}

.small-tile .main-action-title {
  display: block;
  opacity: 1;
  color: #000000;
  margin-top: 0.4em;
  margin-left: 0.25em;
}

.small-tile > p {
  display: none;
  opacity: 0;
}

.small-tile:hover > p {
  display: block;
  margin-top: 0.5em;
  opacity: 1;
}

.small-tile:hover .main-action-title {
  color: #FFFFFF;
  text-transform: lowercase;
}

.small-tile:hover {
  width: 10em;
  background-color: #353b48;
}


.small-tile:focus > p {
  display: block;
  margin-top: 0.5em;
  opacity: 1;
}

.small-tile:focus .main-action-title {
  color: #FFFFFF;
  text-transform: lowercase;
}

.small-tile:focus {
  width: 10em;
  background-color: #353b48;
}


.job-print {
    padding: 12px;
}

.job-print .category-dropdown {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    /* Add some styling */
    float: left;
    min-width: 50px;
    display: block;
    font-size: inherit;
    padding-left: 5px;
    width: 100%;
    max-width: 320px;
    float: right;
    line-height: 1.75;
    color: #333;
    background-color: #ffffff;
    background-image: none;
    -ms-word-break: normal;
    border-color: gray;
    border-width: 0px;
    border-right-width: 1px;
    border-radius: 0px;
    word-break: normal;
}

.job-print .close-button {
    border-width: 0px;
    background-color: rgba(0, 0, 0, 0);
    color: inherit;
}

.job-print-body input {
    font-size: 12px;
    padding: 0px;
}

.job-print-footer {
  display: block;
}

.div-shrink-animation {
  transition: all 0.5s;
}

@keyframes closeOut {
    0% {
        height: 50vh;
    }
    50% {
        height: 25vh;
    }
    100% {
        height: 0vh;
    }
}

.job-print-footer img {
  padding: 0.5em;
  display: inline-block;
}

.job-print-footer img:hover {
  cursor: pointer;
  /*height: 10em;*/
  box-shadow: 0px 0px 8px 1px hsla(0, 0%, 0%, .3), inset 0px 0px 2px 1px #4cd137;
}

.job-print-footer img:active {
  cursor: pointer;
  /*height: 10em;*/
  box-shadow: 0px 0px 8px 1px hsla(0, 0%, 0%, .3), inset 0px 0px 2px 1px #4cd137;
}

.job-print-row-split {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
}

.job-print-row-split .left-most {
    margin-right: auto;
    margin-left: 0;
}

.job-print-row-split .right-most {
    margin-left: auto;
    margin-right: 0;
}

.job-print-sigs-box {
    padding: 5px;
}

.job-print-sig {
    margin: 5px;
}

body {
    padding: 0;
}

/* Alert */

#alert {
    position: relative;
}

#alert:hover:after {
    background: hsla(0, 0%, 0%, .8);
    border-radius: 3px;
    color: #f6f6f6;
    content: 'Click to dismiss';
    font: bold 12px/30px sans-serif;
    height: 30px;
    left: 50%;
    margin-left: -60px;
    position: absolute;
    text-align: center;
    top: 50px;
    width: 120px;
}

#alert:hover:before {
    border-bottom: 10px solid hsla(0, 0%, 0%, .8);
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    content: '';
    height: 0;
    left: 50%;
    margin-left: -10px;
    position: absolute;
    top: 40px;
    width: 0;
}

#alert:target {
    display: none;
}

.alert {
    background-color: #c4453c;
    background-image: -webkit-linear-gradient(135deg, transparent, transparent 25%, hsla(0, 0%, 0%, .05) 25%, hsla(0, 0%, 0%, .05) 50%, transparent 50%, transparent 75%, hsla(0, 0%, 0%, .05) 75%, hsla(0, 0%, 0%, .05));
    background-image: -moz-linear-gradient(135deg, transparent, transparent 25%, hsla(0, 0%, 0%, .1) 25%, hsla(0, 0%, 0%, .1) 50%, transparent 50%, transparent 75%, hsla(0, 0%, 0%, .1) 75%, hsla(0, 0%, 0%, .1));
    background-image: -ms-linear-gradient(135deg, transparent, transparent 25%, hsla(0, 0%, 0%, .1) 25%, hsla(0, 0%, 0%, .1) 50%, transparent 50%, transparent 75%, hsla(0, 0%, 0%, .1) 75%, hsla(0, 0%, 0%, .1));
    background-image: -o-linear-gradient(135deg, transparent, transparent 25%, hsla(0, 0%, 0%, .1) 25%, hsla(0, 0%, 0%, .1) 50%, transparent 50%, transparent 75%, hsla(0, 0%, 0%, .1) 75%, hsla(0, 0%, 0%, .1));
    background-image: linear-gradient(135deg, transparent, transparent 25%, hsla(0, 0%, 0%, .1) 25%, hsla(0, 0%, 0%, .1) 50%, transparent 50%, transparent 75%, hsla(0, 0%, 0%, .1) 75%, hsla(0, 0%, 0%, .1));
    background-size: 20px 20px;
    box-shadow: 0 5px 0 hsla(0, 0%, 0%, .1);
    color: #f6f6f6;
    display: block;
    font: bold 16px/40px sans-serif;
    height: 40px;
    position: absolute;
    text-align: center;
    text-decoration: none;
    top: -45px;
    width: 100%;
    -webkit-animation: alert 1s ease forwards;
    -moz-animation: alert 1s ease forwards;
    -ms-animation: alert 1s ease forwards;
    -o-animation: alert 1s ease forwards;
    animation: alert 1s ease forwards;
}
/* Animation */

@-webkit-keyframes alert {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        top: 0;
    }
}

@-moz-keyframes alert {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        top: 0;
    }
}

@-ms-keyframes alert {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        top: 0;
    }
}

@-o-keyframes alert {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        top: 0;
    }
}

@keyframes alert {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        top: 0;
    }
}

.cancelBtn {
  width: 4em;
  height: 4em;
  background: url("/img/cancel.png") no-repeat;
  background-size: 2.5em;
}

.select-text {
  transition: background 0.5s linear;
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.select-text:hover {
  background: #353b48aa;
  color: white;
  transition: background 0s linear;
}

.select-text:active, .select-text:focus {
  background: #4cd137;
}


.select-text-nostyle {
  padding-top: 0.1em;
  padding-bottom: 0.1em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}


.job-flexbox-row {
  display: flex;
  flex-direction: row;
}

.job-text-item {
  margin-right: 0.5em !important;
}

.navigation-bar {
  display: block;
  outline: 2px solid black;
}

.navigation-bar a {
  margin-right: 0.5em;
  background: #6c7ae0;
  color: #fff;
  text-decoration: none;
}

.navigation-bar a:hover {
  color: #ddd;
}

.navigation-bar a:first-child {
  margin-left: 0.5em;
}

.navigation-bar a:last-child {
  float: right;
}
