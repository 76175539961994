.actionableButton {
  border: none !important;
  box-shadow: none !important;
  opacity: 0.65;
  cursor: pointer;
  padding: 0px !important;
}

.actionableButton:hover, .actionableButton:focus {
  opacity: 1;
}

.deleteItemBtn {
  background: url("/img/trashcan.png") no-repeat;
  background-size: contain;
  width: 2rem;
  height: 2rem;
  position: absolute;
  top: 1rem;
  left: calc(5rem - 6px);
}

.addBtn {
  background: url("/img/inbox-down.png") no-repeat;
  background-size: contain;
}

.saveBtn {
  background: url("/img/accept.png") no-repeat;
  background-size: contain;
}

.cancelBtn {
  background: url("/img/cancel.png") no-repeat;
  background-size: contain;
}

.deleteBtn {
  background: url("/img/delete.png") no-repeat;
  background-size: contain;
}

.editBtn {
  background: url("/img/pencil.png") no-repeat;
  background-size: contain;
}

.editAddBtn {
  background: url("/img/pencil-add.png") no-repeat;
  background-size: contain;
}

.input-action-buttons {
  display: inline-flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: space-evenly;
  text-align: right;
}

.input-action-buttons button {
  display: inline-block !important;
  width: 2.75em;
  height: 2.75em;
  opacity: 0.65;
  box-shadow: 0px 0px 1px 0px #000000ab;
}

.input-action-buttons button:hover, .input-action-buttons button:focus {
  width: 3em !important;
  height: 3em !important;
  box-shadow: 0px 0px 2px 1px #4cd137;
  opacity: 1;
}

.tbl-cell-print-fix {
  margin: 0.15em !important;
}

@media print {
  .tbl-cell-print-fix {
    margin-top: 0.15em !important;
  }
}

.Dropdown-root {
    position: relative
}

.Dropdown-control {
    position: relative;
    overflow: hidden;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 2px;
    box-sizing: border-box;
    color: #333;
    cursor: default;
    outline: 0;
    padding: 8px 52px 8px 10px;
    transition: all 200ms ease
}

.Dropdown-control:hover {
    box-shadow: 0 1px 0 rgba(0, 0, 0, .06)
}

.Dropdown-arrow {
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: ' ';
    display: block;
    height: 0;
    margin-top: -ceil(2.5);
    position: absolute;
    right: 10px;
    top: 14px;
    width: 0
}

.is-open .Dropdown-arrow {
    border-color: transparent transparent #999;
    border-width: 0 5px 5px
}

.Dropdown-menu {
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, .06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1000;
    -webkit-overflow-scrolling: touch
}

.Dropdown-menu .Dropdown-group>.Dropdown-title {
    padding: 8px 10px;
    color: #333;
    font-weight: 700;
    text-transform: capitalize
}

.Dropdown-option {
    box-sizing: border-box;
    color: rgba(51, 51, 51, .8);
    cursor: pointer;
    display: block;
    padding: 8px 10px
}

.Dropdown-option:last-child {
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px
}

.Dropdown-option:hover {
    background-color: #f2f9fc;
    color: #333
}

.Dropdown-option.is-selected {
    background-color: #f2f9fc;
    color: #333
}

.Dropdown-noresults {
    box-sizing: border-box;
    color: #ccc;
    cursor: default;
    display: block;
    padding: 8px 10px
}
